import React from 'react';
import styled from 'styled-components/macro';

import { colors } from './../../constants/colors';
import { SearchIcon } from './../atoms/icons/Search';

export enum EInputSize {
  Full,
  Large,
  Small,
  XSmall
}

export enum EInputAlign {
  Left,
  Center
}
interface InputProps {
  align?: EInputAlign;
  className?: string;
  disabled?: boolean;
  label?: string;
  name: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  register?: () => void;
  rightSpanOnClick?: () => void;
  rightSpanText?: string;
  search?: boolean;
  type?: string;
  value?: string;
  width?: EInputSize;
}

export const TextInput: React.FC<InputProps> = ({
  align = EInputAlign.Left,
  className,
  label,
  onChange,
  name,
  placeholder,
  register,
  type,
  value,
  search,
  disabled,
  rightSpanText,
  rightSpanOnClick,
  width = EInputSize.Full
}) => {
  return (
    <InputWrapper>
      {label ? (
        <StyledLabel>
          {label}
          {rightSpanText && rightSpanOnClick ? (
            <StyledSpan onClick={rightSpanOnClick}>{rightSpanText}</StyledSpan>
          ) : null}
        </StyledLabel>
      ) : null}
      {search && !value && (
        <IconWrap>
          <SearchIcon />
        </IconWrap>
      )}
      <StyledTextInput
        align={align}
        className={className}
        disabled={disabled}
        id={name}
        label={label}
        name={name}
        onChange={onChange}
        placeholder={search ? `${placeholder}` : placeholder}
        type={type || 'text'}
        value={value}
        width={width}
        {...register?.()}
      />
    </InputWrapper>
  );
};

const InputWrapper = styled.div`
  position: relative;
`;

const StyledLabel = styled.label`
  align-items: center;
  color: ${colors.dark.gray};
  display: flex;
  font-size: 14px;
  font-weight: 400;
  height: 10px;
  line-height: 16px;
  margin-bottom: 10px;
  margin-top: 20px;
`;

const IconWrap = styled.span`
  margin-left: 15px;
  padding: 8px;
  position: absolute;

  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

const StyledTextInput = styled.input<InputProps>`
  align-items: center;
  border: 1px solid ${colors.dark.backgroundTitle};
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 16px;
  height: 40px;
  line-height: 16px;
  margin-left: 2px;
  padding-left: 8px;

  width: ${({ width }) => {
    switch (width) {
      case EInputSize.Full:
        return '100%';
      case EInputSize.Large:
        return '520px';
      case EInputSize.Small:
        return '250px';
      case EInputSize.XSmall:
        return '200px';
    }
  }};
  &:focus {
    background: ${colors.light.white};
    border: 3px solid transparent;
    box-shadow: 0px 0px 2px ${colors.dark.green};
    outline: none;
  }
  &::-webkit-input-placeholder {
    text-align: ${({ align }) => {
      switch (align) {
        case EInputAlign.Center:
          return 'center';
        case EInputAlign.Left:
          return 'left';
      }
    }};
  }
`;

const StyledSpan = styled.span`
  align - items: center;
  color: ${colors.dark.green};
  cursor: pointer;
  display: flex;
  float: right;
  font-size: 14px;
  font-weight:  '400';
  line-height: 16px;
  text-align: right;
  margin-left: auto;
`;

import React from 'react';

export const LeftArrowIcon: React.FC<React.HTMLAttributes<HTMLElement>> = ({
  className,
  onClick
}) => (
  <svg
    className={className}
    fill="none"
    height="15"
    onClick={() => onClick}
    viewBox="0 0 15 15"
    width="15"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.48438 12.9706L1.82752 7.31373L7.48437 1.65687"
      stroke="#475352"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
  </svg>
);

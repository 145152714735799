import React from 'react';

import { colors } from 'constants/colors';

interface Props {
  color?: string;
}

export const LayersIcon: React.FC<Props> = ({ color }) => (
  <svg
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.16751 5.07502L9.66751 1.74169C9.77265 1.6958 9.88613 1.67212 10.0008 1.67212C10.1156 1.67212 10.229 1.6958 10.3342 1.74169L17.8342 5.07502C17.9797 5.14131 18.103 5.24805 18.1895 5.38251C18.276 5.51697 18.322 5.67348 18.322 5.83336C18.322 5.99324 18.276 6.14974 18.1895 6.2842C18.103 6.41866 17.9797 6.5254 17.8342 6.59169L10.3342 9.92502C10.229 9.97091 10.1156 9.99459 10.0008 9.99459C9.88613 9.99459 9.77265 9.97091 9.66751 9.92502L2.16751 6.59169C2.02202 6.5254 1.89867 6.41866 1.81218 6.2842C1.72568 6.14974 1.67969 5.99324 1.67969 5.83336C1.67969 5.67348 1.72568 5.51697 1.81218 5.38251C1.89867 5.24805 2.02202 5.14131 2.16751 5.07502Z"
      fill={color || colors.dark.gray}
    />
    <path
      d="M3.25775 8.75L9.65775 11.5917C9.76518 11.64 9.88163 11.6649 9.99942 11.6649C10.1172 11.6649 10.2337 11.64 10.3411 11.5917L16.7411 8.75L17.8411 9.24167C17.9866 9.30795 18.1099 9.41469 18.1964 9.54916C18.2829 9.68362 18.3289 9.84012 18.3289 10C18.3289 10.1599 18.2829 10.3164 18.1964 10.4508C18.1099 10.5853 17.9866 10.692 17.8411 10.7583L10.3411 14.0917C10.2337 14.14 10.1172 14.1649 9.99942 14.1649C9.88163 14.1649 9.76518 14.14 9.65775 14.0917L2.15775 10.7583C2.01226 10.692 1.88891 10.5853 1.80241 10.4508C1.71591 10.3164 1.66992 10.1599 1.66992 10C1.66992 9.84012 1.71591 9.68362 1.80241 9.54916C1.88891 9.41469 2.01226 9.30795 2.15775 9.24167L3.25775 8.75ZM3.25775 12.9167L9.65775 15.7583C9.76518 15.8066 9.88163 15.8316 9.99942 15.8316C10.1172 15.8316 10.2337 15.8066 10.3411 15.7583L16.7411 12.9167L17.8411 13.4083C17.9866 13.4746 18.1099 13.5814 18.1964 13.7158C18.2829 13.8503 18.3289 14.0068 18.3289 14.1667C18.3289 14.3265 18.2829 14.4831 18.1964 14.6175C18.1099 14.752 17.9866 14.8587 17.8411 14.925L10.3411 18.2583C10.2337 18.3066 10.1172 18.3316 9.99942 18.3316C9.88163 18.3316 9.76518 18.3066 9.65775 18.2583L2.15775 14.925C2.01226 14.8587 1.88891 14.752 1.80241 14.6175C1.71591 14.4831 1.66992 14.3265 1.66992 14.1667C1.66992 14.0068 1.71591 13.8503 1.80241 13.7158C1.88891 13.5814 2.01226 13.4746 2.15775 13.4083L3.25775 12.9167Z"
      fill={color || colors.dark.gray}
    />
  </svg>
);

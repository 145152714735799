import { IDataTable } from './../types/dataTable';

export type fundType = {
  capitalClosed: string;
  fundName: string;
  notYetReadyToClose: string;
  readyToClose: string;
  render?: Element;
};

export const fundsTableColumns: IDataTable[] = [
  {
    dataIndex: 'fundName',
    key: 'fundName',
    title: 'Fund Name',
    width: 200
  },
  {
    align: 'right',
    dataIndex: 'allCapital',
    key: 'allCapital',
    title: 'All Commitments',
    width: 150
  },
  {
    align: 'right',
    dataIndex: 'notYetReadyToClose',
    key: 'notYetReadyToClose',
    title: 'Not Yet Ready to Close',
    width: 150
  },
  {
    align: 'right',
    dataIndex: 'readyToClose',
    key: 'readyToClose',
    title: 'Ready to Close',
    width: 150
  },
  {
    align: 'right',
    dataIndex: 'capitalClosed',
    key: 'capitalClosed',
    title: 'Closed Capital',
    width: 150
  }
];

export const teamTableColumns: IDataTable[] = [
  {
    dataIndex: 'name',
    key: 'name'
  },
  {
    dataIndex: 'email',
    key: 'email'
  },
  {
    dataIndex: 'action',
    key: 'action'
  }
];

export type fundDetailType = {
  capitalCommitment: string;
  contactEmail: string;
  contactName: string;
  lpGroup?: string;
  name: string;
  notes: string;
  status: string;
};

export const fundDetailTableColumns: IDataTable[] = [
  {
    dataIndex: 'name',
    key: 'name',
    sort: true,
    title: 'Name'
  },
  {
    align: 'left',
    dataIndex: 'lpGroup',
    key: 'lpGroup',
    sort: true,
    title: 'LP Group'
  },
  {
    align: 'right',
    dataIndex: 'capitalCommitment',
    key: 'capitalCommitment',
    sort: true,
    title: 'Capital Commitment'
  },
  {
    align: 'left',
    className: 'status-content-style',
    dataIndex: 'statusContent',
    key: 'statusContent',
    sort: true,
    title: 'Status'
  },
  {
    align: 'left',
    dataIndex: 'contactName',
    key: 'contactName',
    sort: true,
    title: 'Contact Name'
  },
  {
    align: 'left',
    dataIndex: 'contactEmail',
    key: 'contactEmail',
    sort: true,
    title: 'Contact Email'
  },
  {
    align: 'right',
    className: 'notes-content-style',
    dataIndex: 'notes',
    key: 'notes',
    title: 'Notes from the GD Legal Team'
  }
];

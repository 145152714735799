import { useCurrentUser } from 'hooks/user-context';
import React from 'react';
import styled from 'styled-components/macro';

import { colors } from './../../constants/colors';

export const Header: React.FC = () => {
  const { user } = useCurrentUser();
  const adminGunder = process.env.REACT_APP_PROXY || '/';
  return (
    <Wrapper>
      <Logo href={adminGunder}>
        <LogoImg
          alt="Fund Formation Portal"
          src={'/fund_formation_portal.png'}
        ></LogoImg>
      </Logo>

      <HeaderText></HeaderText>
      {user && user.isAuthenticated ? (
        <StyledLink href="mailto:fundportaladmin@gunder.com">
          Technical Support
          <p>Please contact your legal team for all other questions.</p>
        </StyledLink>
      ) : (
        <></>
      )}
    </Wrapper>
  );
};

const LogoImg = styled.img`
  width: 300px;
`;

const Wrapper = styled.div`
  align-items: flex-end;
  color: ${colors.dark.black};
  display: flex;
  flex-direction: row;
  font-size: 14px;
  height: 50px;
  line-height: 16px;
  margin: -8px;
  margin-top: 10px;
  padding: 15px 100px;
  text-decoration: none;
`;

const Logo = styled.a`
  margin-right: 10px;
`;

const StyledLink = styled.a`
  color: ${colors.dark.green};
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: 500;
  margin-top: auto;
  text-align: right;
  text-decoration: none;

  p {
    color: ${colors.dark.gray};
    font-size: 14px;
    font-weight: 400;
    margin-top: 15px;
  }
`;

// const StyledNavLink = styled(NavLink)`
//   color: ${colors.dark.green};
//   font-size: 18px;
//   font-weight: 500;
//   text-decoration: none;
// `;

const HeaderText = styled.h4`
  flex: 1;
  font-weight: 100;
  position: relative;
  text-transform: uppercase;
  top: 12px;
`;

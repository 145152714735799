import React from 'react';

import { colors } from './../../../constants/colors';

export const LogoutIcon: React.FC = () => (
  <svg fill="none" height="16" viewBox="0 0 16 16" width="16">
    <path
      clipRule="evenodd"
      d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8ZM11.7022 8.42941L8.74959 11.3448C8.27805 11.8153 7.57099 11.1095 8.04253 10.6392L10.0134 8.67414C10.1101 8.5777 10.077 8.49899 9.93876 8.49899H4.50123C4.22393 8.49899 4 8.27558 4 7.99999C4 7.72248 4.22441 7.50099 4.50123 7.50099L9.93876 7.50099C10.0763 7.50099 10.1104 7.42258 10.0134 7.32585L8.04253 5.36078C7.57099 4.89022 8.27805 4.18463 8.74959 4.65519L11.6987 7.55144C11.9465 7.79475 11.9475 8.18726 11.7022 8.42941ZM1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8Z"
      fill={colors.light.white}
      fillRule="evenodd"
    />
  </svg>
);

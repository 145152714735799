import React, { useState } from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

import { EInputSize, TextInput } from '../components/atoms/TextInput';
import { Button, EButtonAlign } from './../components/atoms/Button';
import { RightArrowIcon } from './../components/atoms/icons/RightArrowIcon';
import { colors } from './../constants/colors';
import { fonts } from './../constants/fonts';
import { useAuth } from './../hooks/auth-context';
import { useAxios } from './../hooks/axios-context';
import { useCurrentUser } from './../hooks/user-context';
import { PasswordFormData } from './../types/formTypes';
import { checkPasswordStrength } from './../utils/validations';

export const PasswordUpdate: React.FC = () => {
  const { axios } = useAxios();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const { user } = useCurrentUser();
  const { logout } = useAuth();
  const { replace } = useHistory();

  useEffect(() => {
    if (!user) {
      replace('/login');
    }
  }, []); // eslint-disable-line

  const { handleSubmit, register } = useForm<PasswordFormData>();

  const handleOnClick = async (form: PasswordFormData): Promise<void> => {
    if (user) {
      setLoading(true);
      const payload = {
        email: user.email,
        ...form
      };
      const passwordPassed = checkPasswordStrength(
        payload.password,
        payload.confirmPassword
      );
      if (!passwordPassed) {
        try {
          const { data } = await axios.post(
            `/clients/update-temporary-password`,
            payload
          );
          if (data.success && data.passwordChanged) {
            replace('/login');
            logout();
          } else {
            setErrorMsg(data.message || 'Something went wrong!');
          }
        } catch (err) {
          setErrorMsg(err.message);
        }
      } else {
        setErrorMsg(passwordPassed);
      }
      setLoading(false);
    } else {
      setErrorMsg('Something went wrong!');
    }
  };

  return (
    <Wrapper>
      <form onSubmit={handleSubmit(handleOnClick)}>
        <Container>
          <DescriptionMessage>
            Create a new password. Must be at least 14 characters.
          </DescriptionMessage>
          <div>
            <TextInput
              label="New Password"
              name="password"
              register={() => register('password')}
              type="password"
              width={EInputSize.Full}
            />
          </div>
          <div>
            <TextInput
              label="Confirm Password"
              name="confirmPassword"
              register={() => register('confirmPassword')}
              type="password"
              width={EInputSize.Full}
            />
          </div>
          {errorMsg ? <ErrorMessage>{errorMsg}</ErrorMessage> : null}
          <FooterSection>
            <Button
              displayText={loading ? 'Loading...' : 'Get Started'}
              iconAlign={EButtonAlign.Right}
              type="submit"
            >
              <RightArrowIcon />
            </Button>
          </FooterSection>
        </Container>
      </form>
    </Wrapper>
  );
};

const ErrorMessage = styled.div`
  color: ${colors.errors.primary};
  margin-bottom: 15px;
  margin-top: 15px;
`;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  height: auto;
  justify-content: center;
  margin: 40px auto;
  margin-top: 150px;
  max-width: 450px;
  text-align: center;
`;

const DescriptionMessage = styled.p`
  align-items: center;
  color: ${colors.dark.green};
  display: flex;
  font-family: ${fonts.primary};
  font-size: 16px;
  line-height: 28px;
  text-align: left;
`;

const Container = styled.div`
  width: 520px;
`;

const FooterSection = styled.div`
  margin-top: 20px;
`;

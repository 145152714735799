import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components/macro';
import Swal from 'sweetalert2';

import { EInputSize, TextInput } from '../components/atoms/TextInput';
import { Button, EButtonAlign } from './../components/atoms/Button';
import { RightArrowIcon } from './../components/atoms/icons/RightArrowIcon';
import { colors } from './../constants/colors';
import { useAxios } from './../hooks/axios-context';
import { ForgotPasswordFormData } from './../types/formTypes';
import { validateEmail } from './../utils/validations';

export const ForgotPassword: React.FC = () => {
  const { axios } = useAxios();
  const [loading, setLoading] = useState<boolean>(false);
  const [showLoadingScreen, setShowLoadingScreen] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const { getValues, handleSubmit, register } =
    useForm<ForgotPasswordFormData>();

  const sendEmail = async (
    payload: ForgotPasswordFormData,
    resend?: boolean
  ): Promise<void> => {
    try {
      const { data } = await axios.post(`/clients/forgot-password`, payload);
      if (resend) {
        Swal.fire(
          'Reset password',
          data.message,
          data.success ? 'success' : 'error'
        );
      } else {
        Swal.fire(
          'Reset password',
          data.message,
          data.success ? 'success' : 'error'
        );
      }
      if (data.success) {
        setShowLoadingScreen(true);
      }
    } catch (err) {
      setErrorMsg(err.message);
    }
  };

  const handleOnClick = async (form: ForgotPasswordFormData): Promise<void> => {
    if (validateEmail(form.email)) {
      setLoading(true);
      sendEmail(form);
    } else {
      setErrorMsg('You have entered an invalid email address!');
    }
    setLoading(false);
  };

  return (
    <Wrapper>
      <Container>
        <DescriptionMessage>Forgot Password?</DescriptionMessage>
        {!showLoadingScreen ? (
          <form onSubmit={handleSubmit(handleOnClick)}>
            <div>
              <TextInput
                label="Email Address"
                name="email"
                register={() => register('email')}
                width={EInputSize.Full}
              />
            </div>
            {errorMsg ? <ErrorMessage>{errorMsg}</ErrorMessage> : null}
            <FooterSection>
              <Button
                displayText={
                  loading ? 'Loading...' : 'Send a Password Reset Link'
                }
                iconAlign={EButtonAlign.Right}
                type="submit"
              >
                <RightArrowIcon />
              </Button>
            </FooterSection>
          </form>
        ) : (
          <>
            <LoadingText>
              An email has been sent with a link to reset your password. If you
              do not receive the email within a few minutes,&nbsp;
              <a
                href="/#"
                onClick={async (e) => {
                  e.preventDefault();
                  await sendEmail({ email: getValues('email') }, true);
                }}
              >
                click here to resend
              </a>
              .
            </LoadingText>
            {errorMsg ? <ErrorMessage>{errorMsg}</ErrorMessage> : null}
          </>
        )}
      </Container>
    </Wrapper>
  );
};

const ErrorMessage = styled.div`
  color: ${colors.errors.primary};
  margin-bottom: 15px;
  margin-top: 15px;
`;

const LoadingText = styled.div`
  color: ${colors.dark.backgroundTitle};
  font-size: 12px;
  line-height: 22px;
  width: 105%;

  & a {
    color: ${colors.dark.backgroundTitle};
    text-decoration: underline;
  }
`;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  height: auto;
  justify-content: center;
  margin: 40px auto;
  margin-top: 150px;
  max-width: 450px;
  text-align: center;
`;

const DescriptionMessage = styled.p`
  align-items: center;
  color: ${colors.dark.green};
  font-size: 20px;
  font-weight: 100;
  line-height: 28px;
  text-align: center;
`;

const Container = styled.div`
  width: 520px;
`;

const FooterSection = styled.div`
  margin-top: 20px;
`;

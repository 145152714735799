import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import Swal from 'sweetalert2';

import { EInputSize, TextInput } from '../components/atoms/TextInput';
import { Button, EButtonAlign } from './../components/atoms/Button';
import { RightArrowIcon } from './../components/atoms/icons/RightArrowIcon';
import { colors } from './../constants/colors';
import { getConfig, useAuth } from './../hooks/auth-context';
import { useAxios } from './../hooks/axios-context';
import { PasswordFormData } from './../types/formTypes';
import { checkPasswordStrength } from './../utils/validations';

export const ResetPassword: React.FC = () => {
  const { axios } = useAxios();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const { logout } = useAuth();
  const { replace } = useHistory();
  const location = useLocation();
  const token = location.search.split('rs=')[1];

  const { handleSubmit, register } = useForm<PasswordFormData>();

  useEffect(() => {
    if (token) {
      localStorage.clear();
      verifyToken(token);
    }
  }, []); //eslint-disable-line

  const handleOnClick = async (form: PasswordFormData): Promise<void> => {
    setLoading(true);
    const payload = {
      ...form
    };
    const passwordPassed = checkPasswordStrength(
      payload.password,
      payload.confirmPassword
    );
    if (!passwordPassed) {
      try {
        const { data } = await axios.post(`/clients/reset-password`, payload);
        if (data.success && data.passwordChanged) {
          replace('/login');
          logout();
        } else {
          setErrorMsg(data.message || 'Something went wrong!');
        }
      } catch (err) {
        setErrorMsg(err.message);
      }
    } else {
      setErrorMsg(passwordPassed);
    }
    setLoading(false);
  };

  const verifyToken = async (rsToken: string): Promise<void> => {
    try {
      localStorage.setItem('auth-client-token', rsToken);
      const { data } = await axios.post(
        `/clients/forgot-password/verify`,
        { rsToken },
        getConfig()
      );
      if (!data.success) {
        await Swal.fire('Token Expired', data.message, 'error');
        replace('login');
      }
    } catch (err) {
      setErrorMsg(err.message);
    }
  };

  return (
    <Wrapper>
      <form onSubmit={handleSubmit(handleOnClick)}>
        <Container>
          <DescriptionMessage>Enter a new password below.</DescriptionMessage>
          <div>
            <TextInput
              label="New Password"
              name="password"
              register={() => register('password')}
              type="password"
              width={EInputSize.Full}
            />
          </div>
          <div>
            <TextInput
              label="Confirm Password"
              name="confirmPassword"
              register={() => register('confirmPassword')}
              type="password"
              width={EInputSize.Full}
            />
          </div>
          {errorMsg ? <ErrorMessage>{errorMsg}</ErrorMessage> : null}
          <FooterSection>
            <Button
              displayText={loading ? 'Loading...' : 'Back To Login'}
              iconAlign={EButtonAlign.Right}
              type="submit"
            >
              <RightArrowIcon />
            </Button>
          </FooterSection>
        </Container>
      </form>
    </Wrapper>
  );
};

const ErrorMessage = styled.div`
  color: ${colors.errors.primary};
  margin-bottom: 15px;
  margin-top: 15px;
`;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  height: auto;
  justify-content: center;
  margin: 40px auto;
  margin-top: 150px;
  max-width: 450px;
  text-align: center;
`;

const DescriptionMessage = styled.p`
  align-items: center;
  color: ${colors.dark.green};
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 50px;
  text-align: left;
`;

const Container = styled.div`
  width: 520px;
`;

const FooterSection = styled.div`
  margin-top: 20px;
`;

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Login } from 'screens/Login';

import { useCurrentUser } from '../hooks/user-context';
import { Account } from '../screens/Account';
import { ForgotPassword } from '../screens/ForgotPassword';
import { FundDetail } from '../screens/FundDetail';
import { Funds } from '../screens/Funds';
import { Loading } from '../screens/Loading';
import { PasswordUpdate } from '../screens/PasswordUpdate';
import { ResetPassword } from '../screens/ResetPassword';
import { SignUp } from '../screens/SignUp';
import { RouteItem } from '../types/Navigation';
import { LayersIcon } from './../components/atoms/icons/LayersIcon';
import { UserIcon } from './../components/atoms/icons/UserIcon';
import { ProtectedRoute } from './ProtectedRoute';

export const routes: RouteItem[] = [
  {
    Component: Funds,
    exact: true,
    path: '/',
    protectedRoute: true,
    title: 'Funds'
  },
  {
    Component: Login,
    exact: true,
    path: '/login',
    title: 'Login'
  },
  {
    Component: Funds,
    exact: true,
    icon: LayersIcon,
    isMenuItem: true,
    path: '/funds',
    protectedRoute: true,
    title: 'Funds'
  },
  {
    Component: SignUp,
    exact: true,
    path: '/signup',
    title: 'Setup Account'
  },
  {
    Component: ForgotPassword,
    exact: true,
    path: '/forgot-password',
    title: 'Forgot Password'
  },
  {
    Component: ResetPassword,
    exact: true,
    path: '/resetPassword',
    title: 'Reset Password'
  },
  {
    Component: PasswordUpdate,
    exact: true,
    path: '/password-update',
    title: 'Password Update'
  },
  {
    Component: FundDetail,
    exact: true,
    path: '/funds/:id',
    protectedRoute: true,
    title: 'Fund Detail'
  },
  {
    Component: FundDetail, // multi-funds
    exact: true,
    path: '/funds/:id/:fundNumber',
    protectedRoute: true,
    title: 'Fund Detail'
  },
  {
    Component: Account,
    exact: true,
    icon: UserIcon,
    isMenuItem: true,
    path: '/account',
    protectedRoute: true,
    title: 'Manage Account'
  }
];

export const Routes: React.FC = () => {
  const { isLoading } = useCurrentUser();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Switch>
      {routes.map(({ Component, exact, path, protectedRoute }) => {
        if (protectedRoute) {
          return (
            <ProtectedRoute
              component={Component}
              exact={!!exact}
              key={path}
              path={path}
            />
          );
        }

        return (
          <Route component={Component} exact={!!exact} key={path} path={path} />
        );
      })}
    </Switch>
  );
};

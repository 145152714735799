import styled from 'styled-components/macro';

import { colors } from '../../constants/colors';

export const Tooltip = styled.div`
  color: ${colors.dark.gray};
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  position: relative;
  width: 100%;

  .tooltip-text {
    background: ${colors.light.white};
    border-radius: 8px;
    box-shadow: 2px 2px 2px ${colors.dark.gray};
    padding: 10px 25px;
    position: absolute;

    text-align: center;
    visibility: hidden;
    width: auto;
    z-index: 100;
  }

  &:hover .tooltip-text {
    visibility: visible;
  }
`;

export const TooltipAmount = styled.div`
  color: ${colors.dark.gray};
  cursor: pointer;
  font-size: 24px;
  line-height: 24px;
  margin-top: -10px;
  .tooltip-text {
    background: ${colors.light.white};
    border-radius: 8px;
    border-radius: 6px;
    box-shadow: 2px 2px 2px ${colors.dark.gray};

    padding: 10px 25px;
    position: absolute;

    text-align: center;
    visibility: hidden;
    width: auto;
    z-index: 100;
  }

  &:hover .tooltip-text {
    visibility: visible;
  }
`;

export const TooltipExpand = styled.div`
  color: ${colors.dark.gray};
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

  .tooltip-text {
    background-color: ${colors.light.white};
    border-radius: 8px;
    box-shadow: 0 2px 6px 2px #333, -6px -7px 5px -10px #333;
    padding: 0px 25px;
    overflow: hidden;
    white-space: break-spaces;
    transform: translateX(-35px);
    position: absolute;
    text-align: center;
    visibility: hidden;
    width: 400px;
    z-index: 100;

    & svg {
      position: absolute;
      right: 15px;
      top: 15px;
    }
  }

  &:hover::after {
    background: ${colors.light.white};
    border-color: #333;
    border-style: solid;
    border-width: 0 0px 2px 0;
    width: 30px;
    height: 1px;
    content: ' ';
    position: absolute;
    transform: translateX(-60%);
  }

  &:hover .tooltip-last-child {
    top: -170%;
  }

  &:hover .tooltip-text {
    visibility: visible;
  }
`;

import Table from 'rc-table';
import React from 'react';
import { useEffect } from 'react';
import styled from 'styled-components/macro';

import { SortDownIcon } from './../../components/atoms/icons/SortDownIcon';
import { SortUpIcon } from './../../components/atoms/icons/SortUpIcon';
import { colors } from './../../constants/colors';
import { fonts } from './../../constants/fonts';
import { Pagination, PaginationType } from './../molecules/Pagination';
import { TableHeader } from './../molecules/TableHeader';

interface DataTableProps {
  cancelSort?: (arr: any) => void;
  clickable?: boolean;
  columns: any[];
  columnSelected?: string;
  csvData?: any[];
  csvTitle?: string;
  data: any[];
  emptyText?: string;
  headers?: any[];
  hideHeader?: boolean;
  onRowClick: (arr: any[], arr2: number | undefined) => void;
  onSearch?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  pagination?: PaginationType;
  search?: string;
  sortDirection?: string;
  sortHandler: (arr: any) => void;
  tableHeaderColumns?: boolean;
  title?: string;
}

export const DataTable: React.FC<DataTableProps> = ({
  clickable,
  columns,
  columnSelected,
  csvData,
  csvTitle,
  data,
  emptyText,
  headers,
  hideHeader = false,
  onRowClick,
  onSearch,
  pagination,
  search,
  sortDirection,
  sortHandler,
  tableHeaderColumns,
  title
}) => {
  useEffect(() => {
    //will append headers into a scrollable table
    const headerTable = document.getElementById('style-headers');
    const contentTable = document.getElementsByClassName('rc-table-content')[0];
    if (contentTable && title === 'Investors') {
      contentTable?.prepend(headerTable as any);
    }
  }, [title]);

  return (
    <Wrapper className={title}>
      {!hideHeader && title ? (
        <TableHeader
          csvTitle={csvTitle}
          data={csvData}
          headers={headers}
          onSearch={onSearch}
          search={search}
          title={title}
        />
      ) : null}
      <StyledHeaders id="style-headers">
        {!tableHeaderColumns &&
          columns.map((column) =>
            column.sort ? (
              <StyledHeaderButton
                id={column.title}
                onClick={(e) => {
                  const target = e.target as Element;
                  if (target.id) {
                    sortHandler(column);
                  }
                }}
              >
                <StyledHeaderTitle
                  active={column.title === columnSelected}
                  onClick={() => sortHandler(column)}
                >
                  {column.title === 'Status' && (
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  )}
                  {column.title} &nbsp;
                </StyledHeaderTitle>
                <StyledIcon onClick={() => sortHandler(column)}>
                  {sortDirection === 'asc' &&
                  column.title === columnSelected ? (
                    <SortUpIcon
                      color={
                        column.title === columnSelected
                          ? colors.dark.black
                          : colors.dark.backgroundTitle
                      }
                    />
                  ) : (
                    <SortDownIcon
                      color={
                        column.title === columnSelected
                          ? colors.dark.black
                          : colors.dark.backgroundTitle
                      }
                    />
                  )}
                </StyledIcon>
              </StyledHeaderButton>
            ) : (
              <StyledHeaderColumn>
                <StyledHeaderTitle>{column.title}</StyledHeaderTitle>
              </StyledHeaderColumn>
            )
          )}
      </StyledHeaders>
      <Table
        columns={columns}
        components={clickable ? actionComponents : components}
        data={data}
        emptyText={emptyText}
        onRow={(record, index) => ({
          onClick: () => onRowClick(record, index)
        })}
        scroll={title === 'Investors' ? { x: 'auto hidden' } : {}}
        showHeader={tableHeaderColumns}
        tableLayout="auto"
      />
      <Pagination data={pagination} />
    </Wrapper>
  );
};

const StyledIcon = styled.span`
  z-index: 100;
`;

const StyledHeaders = styled.div`
  -moz-user-select: none; /* Chrome/Safari */
  -ms-user-select: none; /* Firefox */
  -o-user-select: none; /* IE10+ */
  -webkit-user-select: none;
  display: grid;
  grid-template-columns: 350px 100px 250px 250px 350px 350px 100px;
  margin-bottom: 30px;
  user-select: none;

  @media screen and (min-width: 2000px) {
    grid-template-columns: 1.5fr 0.5fr 1fr 1fr 1fr 1fr 1fr;
  }
`;

interface StyledProps {
  active?: boolean;
}

const StyledHeaderTitle = styled.div<StyledProps>`
  color: ${(props) =>
    props.active ? colors.dark.black : colors.light.backgroundTitle};
  float: left;
  font-family: ${fonts.primary};
  font-size: 16px;
  font-weight: 400;
`;

const StyledHeaderColumn = styled.div`
  flex: 3;
  &:last-child {
    justify-self: right;
  }
`;

const StyledHeaderButton = styled.div`
  cursor: pointer;
  flex: 2;
  ${(props) =>
    props.id === 'Capital Commitment' ? `justify-self: right;` : ''}
  ${(props) => (props.id === 'Status' ? `justify-self: center;` : '')}
`;

const ActionTableRow = styled.tr<DataTableProps>`
  & td {
    transition: all 0.3s;
    border-bottom: 1px solid #edeeee;
    font-weight: 100;
    font-size: 16px;
    color: ${colors.dark.black};
  }

  &:hover td {
    background: ${colors.light.gray};
    transform: scale(1.01);
  }
`;

const TableRow = styled.tr<DataTableProps>`
  & td {
    border-bottom: 1px solid #edeeee;
    color: ${colors.dark.black};
    font-size: 16px;
    font-weight: 100;
    transition: all 0.3s;
  }
`;

const actionComponents = {
  body: {
    row: ActionTableRow
  }
};

const components = {
  body: {
    row: TableRow
  }
};

const Wrapper = styled.div`
  & table {
    color: ${colors.dark.backgroundTitle};
    font-size: 16px;
    font-weight: 500;
    line-height: 46px;
    text-align: left;
    width: 100%;
  }
  ${(props) =>
    props.className === 'All Funds'
      ? `table { 
        @media screen and (max-width: 1345px) { 
          width: 1048px; 
          overflow: scroll; 
        } 
      }`
      : ''}
  ${(props) =>
    props.className === 'Investors'
      ? `
      & tr {
        display: grid;
        grid-template-columns: 350px 100px 250px 250px 350px 350px 100px;

         @media screen and (min-width: 2000px) {
          grid-template-columns: 1.5fr 0.5fr 1fr 1fr 1fr 1fr 1fr;
        }
      }

      & table {
        white-space: nowrap;
        @media screen and (max-width: 1300px) {
          display: block;
        }
      }
      `
      : ''}
  & th {
    color: ${colors.light.backgroundTitle};
    font-family: ${fonts.primary};
    font-size: 16px;
    font-weight: 400;
    padding-bottom: 15px;
  }
`;

import React, { useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import Swal from 'sweetalert2';

import { EInputSize, TextInput } from '../components/atoms/TextInput';
import { Button, EButtonAlign } from './../components/atoms/Button';
import { RightArrowIcon } from './../components/atoms/icons/RightArrowIcon';
import { colors } from './../constants/colors';
import { getConfig, useAuth } from './../hooks/auth-context';
import { useAxios } from './../hooks/axios-context';
import { useCurrentUser } from './../hooks/user-context';
import { SignUpFormData } from './../types/formTypes';

export const SignUp: React.FC = () => {
  const { axios } = useAxios();
  const [loading, setLoading] = useState<boolean>(false);
  const { setUser, user } = useCurrentUser();
  const { signup } = useAuth();
  const { replace } = useHistory();
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const { handleSubmit, register, setValue } = useForm<SignUpFormData>();
  const location = useLocation();
  const token = location.search.split('t=')[1];

  const handleOnClick = async (form: SignUpFormData): Promise<void> => {
    if (!user) {
      return;
    }

    setLoading(true);
    const token = location.search.split('t=')[1];
    const data = await signup(form, token, user.email);

    if (data.success && data.user) {
      setUser(data.user);
      replace('/password-update');
    } else {
      setErrorMsg(data.message ?? null);
    }
    setLoading(false);
  };

  const resendTmpPassword = async (): Promise<void> => {
    if (user && user.email) {
      try {
        const { data } = await axios.post(
          `/clients/resend-temporary-password`,
          { email: user.email }
        );
        Swal.fire(data.message, '', 'success');
      } catch (err) {
        setErrorMsg(err.message);
      }
    } else {
      Swal.fire('Warning', 'Please insert valid email address.', 'warning');
    }
  };

  const verifyToken = useCallback(
    async (token: string): Promise<void> => {
      try {
        localStorage.setItem('auth-client-token', token);
        const { data } = await axios.post(
          `/clients/validate/signup`,
          { token },
          getConfig()
        );
        if (data.success) {
          setValue('firstName', data.user.firstName);
          setValue('lastName', data.user.lastName);
          setUser(data.user);
        }
      } catch (err) {
        console.log(err);
        setErrorMsg(
          'Token is invalid or has expired. please ask for a resend.'
        );
      }
    },
    [axios, setUser, setValue]
  );

  useEffect(() => {
    if (token) {
      localStorage.clear();
      verifyToken(token);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Wrapper>
      <form onSubmit={handleSubmit(handleOnClick)}>
        <Container>
          <DescriptionMessage>
            Enter the temporary password you received via email to create your
            account.
          </DescriptionMessage>
          <GroupFields>
            <StyledField>
              <TextInput
                label="First Name"
                name="firstName"
                register={() => register('firstName')}
                width={EInputSize.Small}
              />
            </StyledField>
            <StyledField>
              <TextInput
                label="Last Name"
                name="lastName"
                register={() => register('lastName')}
                width={EInputSize.Small}
              />
            </StyledField>
          </GroupFields>
          <div>
            <TextInput
              disabled
              label="Email Address"
              name="email"
              value={user ? user.email : ''}
              width={EInputSize.Full}
            />
          </div>
          <TextInput
            label="Temporary Password"
            name="temporaryPassword"
            register={() => register('temporaryPassword')}
            rightSpanOnClick={() => resendTmpPassword()}
            rightSpanText={'Resend Password'}
            type="password"
            width={EInputSize.Full}
          />
          {errorMsg ? <ErrorMessage>{errorMsg}</ErrorMessage> : null}
          <FooterSection>
            <Button
              displayText={loading ? 'Loading...' : 'Create Account'}
              iconAlign={EButtonAlign.Right}
              type="submit"
            >
              <RightArrowIcon />
            </Button>
          </FooterSection>
        </Container>
      </form>
    </Wrapper>
  );
};

const ErrorMessage = styled.div`
  color: ${colors.errors.primary};
  margin-bottom: 15px;
  margin-top: 15px;
`;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  height: auto;
  justify-content: center;
  margin: 40px auto;
  margin-top: 150px;
  max-width: 500px;
  text-align: center;
`;

const DescriptionMessage = styled.p`
  align-items: center;
  color: ${colors.dark.green};
  display: flex;
  font-size: 16px;
  font-weight: 100;
  line-height: 28px;
  text-align: left;
`;

const Container = styled.div`
  width: 520px;
`;

const GroupFields = styled.div`
  display: flex;
`;

const StyledField = styled.div`
  margin-right: 20px;
`;

const FooterSection = styled.div`
  margin-top: 20px;
`;

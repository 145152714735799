/* eslint-disable sort-keys */
/* eslint-disable typescript-sort-keys/interface */
import React, { useState, useEffect, ReactElement } from 'react';
import { Redirect } from 'react-router-dom';

import { DataTable } from './../components/organisms/DataTable';
import { fundHeaders, pagination } from './../constants/pagination';
import { fundsTableColumns } from './../constants/tableColumns';
import { useAxios } from './../hooks/axios-context';
import { useCurrentUser } from './../hooks/user-context';
import { Layout } from './../navigation/Layout';
import { formatDataFunds } from './../utils/format';

let page = 1;

type ResultsType = {
  name: string;
  lpGroup: string;
  capitalCommitment: string;
  contactEmail: ReactElement;
  contactName: string;
  notes: string;
  status: string;
  statusContent: ReactElement;
  lpGroupText?: string;
};

export const Funds: React.FC = () => {
  const { axios } = useAxios();
  const [redirect, setRedirect] = useState('');
  const [results, setResults] = useState<ResultsType[]>([]);
  const [csvData, setCSVData] = useState<ResultsType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [pages, setPages] = useState<number[]>([1]);
  const { user } = useCurrentUser();

  useEffect(() => {
    if (user?.firm) {
      getFunds(user.firm._id);
    }
  }, []); // eslint-disable-line

  if (!user) {
    return <Redirect to="/login" />;
  }

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  const nextPage = (): void => {
    page += 1;
    setResults([]);
    getFunds(user.firm._id, page);
  };

  const previusPage = (): void => {
    if (page > 1) {
      page -= 1;
      setResults([]);
      getFunds(user.firm._id, page);
    }
  };

  const selectedPage = (e?: React.MouseEvent<HTMLElement>): void => {
    if (!e) {
      return;
    }
    page = Number((e.target as HTMLElement).innerText);
    setResults([]);
    getFunds(user.firm._id, page);
  };

  const getFunds = async (firm: string, _page?: number): Promise<void> => {
    setLoading(true);
    const { data } = await axios.get(`/clients/firm/${firm}/funds/1/500`);
    const response = await axios.get(
      `/clients/firm/${firm}/funds/${_page ?? page}/${pagination.MAX}`
    );
    const results = formatDataFunds(response.data.funds);
    const csvData = formatDataFunds(data.funds);
    if (csvData) {
      csvData?.forEach((item: any) => delete item?.id);
    }
    const count = Math.ceil(data.funds.length / pagination.MAX);
    const pages = Array.from(Array(count).keys()).reverse();
    setPages(pages);
    setResults(results);
    setCSVData(csvData);
    setLoading(false);
  };

  return (
    <Layout>
      <DataTable
        cancelSort={() => {}}
        clickable
        columns={fundsTableColumns}
        csvData={csvData}
        csvTitle={user?.firm.name}
        data={results}
        emptyText={loading ? 'Loading...' : ''}
        headers={fundHeaders}
        onRowClick={(item: any) => {
          setRedirect(`/funds/${item.id}`);
        }}
        onSearch={(e: any) => {
          setSearchText(e.target.value.toLowerCase());
          if (e.target.value !== '') {
            const _results = results.filter((result: any) =>
              result.fundName
                ?.toLowerCase()
                .includes(e.target.value.toLowerCase())
            );
            setResults(_results);
          } else {
            getFunds(user.firm._id);
          }
        }}
        pagination={{
          next: () => nextPage(),
          page,
          pages,
          previus: () => previusPage(),
          selected: (e) => selectedPage(e)
        }}
        search={searchText}
        sortHandler={() => {}}
        tableHeaderColumns
        title="All Funds"
      />
    </Layout>
  );
};

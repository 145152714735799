import React from 'react';

import { colors } from './../../../constants/colors';

export const DownloadIcon: React.FC = () => (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99979 9.99999V7.99999C9.99979 7.46956 9.78907 6.96085 9.414 6.58578C9.03893 6.21071 8.53022 5.99999 7.99979 5.99999C7.46935 5.99999 6.96065 6.21071 6.58557 6.58578C6.2105 6.96085 5.99979 7.46956 5.99979 7.99999V9.99999H3.99979C3.34808 10.0029 2.71788 9.76702 2.22822 9.33695C1.73856 8.90689 1.42333 8.31239 1.3421 7.66576C1.26086 7.01914 1.41923 6.36514 1.78729 5.82732C2.15535 5.28949 2.70762 4.90507 3.33979 4.74666C3.39767 3.86534 3.77173 3.03453 4.3932 2.40695C5.01467 1.77937 5.84179 1.39721 6.7225 1.33071C7.60321 1.26421 8.47833 1.51784 9.18699 2.04499C9.89565 2.57213 10.3902 3.33736 10.5798 4.19999C11.0347 4.02151 11.5263 3.9571 12.0118 4.01238C12.4973 4.06767 12.9619 4.24098 13.365 4.51716C13.7681 4.79335 14.0975 5.16403 14.3243 5.59682C14.5512 6.02961 14.6687 6.51135 14.6665 6.99999C14.6665 7.79564 14.3504 8.5587 13.7878 9.12131C13.2252 9.68392 12.4621 9.99999 11.6665 9.99999H9.99979Z"
      fill={colors.light.white}
    />
    <path
      d="M7.33365 12.3933V8C7.33365 7.82319 7.40389 7.65362 7.52891 7.5286C7.65394 7.40357 7.82351 7.33334 8.00032 7.33334C8.17713 7.33334 8.3467 7.40357 8.47172 7.5286C8.59675 7.65362 8.66699 7.82319 8.66699 8V12.3933L9.53365 11.5267C9.66195 11.4183 9.82643 11.3623 9.9942 11.37C10.162 11.3776 10.3207 11.4483 10.4386 11.5679C10.5565 11.6875 10.6249 11.8472 10.6302 12.0151C10.6355 12.1829 10.5772 12.3466 10.467 12.4733L8.46699 14.4733C8.34237 14.5955 8.17482 14.6639 8.00032 14.6639C7.82582 14.6639 7.65827 14.5955 7.53365 14.4733L5.53365 12.4733C5.42347 12.3466 5.36518 12.1829 5.37045 12.0151C5.37571 11.8472 5.44414 11.6875 5.56205 11.5679C5.67996 11.4483 5.83867 11.3776 6.00644 11.37C6.17421 11.3623 6.33869 11.4183 6.46699 11.5267L7.33365 12.3933Z"
      fill={colors.light.white}
    />
  </svg>
);

export const status = {
  CLOSED: 'Closed',
  IN_PROGRESS: 'In Progress',
  PENDING: 'Pending',
  READY_TO_CLOSE: 'Ready to close',
  SUBMITTED: 'Submitted for Review',
  SUBMITTED_TYPE: 'Submitted'
};

export const statusOrder = [
  status.CLOSED,
  status.READY_TO_CLOSE,
  status.SUBMITTED,
  status.SUBMITTED_TYPE,
  status.IN_PROGRESS,
  status.PENDING
];

export const resolveStatus = (
  invStatus: string,
  submitted: boolean
): string => {
  if (submitted && invStatus === status.PENDING) {
    return 'Submitted';
  }
  switch (invStatus) {
    case status.CLOSED:
      return status.CLOSED;
    case status.READY_TO_CLOSE:
      return status.READY_TO_CLOSE;
    case status.SUBMITTED:
      return status.SUBMITTED;
    case status.SUBMITTED_TYPE:
      return status.SUBMITTED_TYPE;
    default:
      return status.IN_PROGRESS;
  }
};

import React from 'react';

import { colors } from './../../../constants/colors';

export const RightArrowIcon: React.FC<React.HTMLAttributes<HTMLElement>> = ({
  className
}) => (
  <svg
    className={className}
    fill="none"
    height="14"
    viewBox="0 0 9 14"
    width="9"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.65625 12.3137L7.3131 6.65687L1.65625 1.00001"
      stroke={colors.dark.green}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

export const colors = {
  dark: {
    backgroundGrey: '#FAFAFA',
    backgroundTitle: '#868686',
    black: '#000000',
    gray: '#475352',
    green: '#285E83' //blue
  },
  errors: {
    primary: 'red'
  },
  light: {
    backgroundTitle: '#656565',
    black: '#475352',
    gray: '#edeeee',
    green: '#EAEEF1', //blue
    white: '#ffffff'
  },
  status: {
    closed: '#177245',
    inProgress: '#C1301C',
    readyToClose: '#C96112',
    submitted: '#C4A705'
  }
};

/* eslint-disable sort-keys */
import { status } from './../constants/status';
import {
  calculateAllCapital,
  calculateAmountClosedToDate,
  formatter,
  getNotYetReadyToCloseStatus,
  getStatusInfo,
  InvestorFunds
} from './format';

export const calculateAmountClosedToDateMultiFund2 = (fund: {
  investorFunds: InvestorFunds[];
  limitedPartnerFunds: InvestorFunds[];
}): number | string => {
  let closedAmt = 0;

  if (fund) {
    fund.investorFunds.forEach((inv) => {
      const capital2 = inv.generalInfo?.capitalCommitmentFund2
        ? inv.generalInfo.capitalCommitmentFund2
        : 0;
      if (inv.status === 'Closed') {
        closedAmt += Number(capital2);
      }
    });
    fund.limitedPartnerFunds.forEach((inv) => {
      const capital2 = inv.generalInfo?.capitalCommitmentFund2
        ? inv.generalInfo.capitalCommitmentFund2
        : 0;
      if (inv.status === 'Closed') {
        closedAmt += Number(capital2);
      }
    });
  }
  return formatter.format(closedAmt);
};

export const calculateAmountClosedToDateMultiFund3 = (fund: {
  investorFunds: InvestorFunds[];
  limitedPartnerFunds: InvestorFunds[];
}): number | string => {
  let closedAmt = 0;

  if (fund) {
    fund.investorFunds.forEach((inv) => {
      const capital3 = inv.generalInfo?.capitalCommitmentFund3
        ? inv.generalInfo.capitalCommitmentFund3
        : 0;
      if (inv.status === 'Closed') {
        closedAmt += Number(capital3);
      }
    });
    fund.limitedPartnerFunds.forEach((inv) => {
      const capital3 = inv.generalInfo?.capitalCommitmentFund3
        ? inv.generalInfo.capitalCommitmentFund3
        : 0;
      if (inv.status === 'Closed') {
        closedAmt += Number(capital3);
      }
    });
  }
  return formatter.format(closedAmt);
};

export const resolveMultiFund = (funds: any[]): any => {
  const multiFundsResults: any = [];
  const filteredMultifunds = funds.filter(
    (fund) =>
      fund.fundType.identifier === 'limited_partnership_multi-fund' ||
      fund.fundType.identifier === 'partnership_abbr_multi-fund'
  );
  if (filteredMultifunds && filteredMultifunds.length > 0) {
    filteredMultifunds.forEach((f) => {
      if (f.fundLegalName) {
        multiFundsResults.push({
          fundName: f.fundLegalName,
          allCapital: calculateAllCapital(f),
          capitalClosed: calculateAmountClosedToDate(f),
          notYetReadyToClose: formatter.format(
            getNotYetReadyToCloseStatus(
              [...f.investorFunds, ...f.limitedPartnerFunds],
              status.IN_PROGRESS,
              status.PENDING,
              status.SUBMITTED_TYPE,
              status.SUBMITTED
            ).capital
          ),
          readyToClose: formatter.format(
            getStatusInfo(
              [...f.investorFunds, ...f.limitedPartnerFunds],
              status.READY_TO_CLOSE
            ).capital
          ),
          id: f._id
        });
      }
      if (f.fund2LegalName) {
        multiFundsResults.push({
          fundName: f.fund2LegalName,
          allCapital: calculateAllCapital(f, 'capitalCommitmentFund2'),
          capitalClosed: calculateAmountClosedToDateMultiFund2(f),
          notYetReadyToClose: formatter.format(
            getNotYetReadyToCloseStatus(
              [...f.investorFunds, ...f.limitedPartnerFunds],
              status.IN_PROGRESS,
              status.PENDING,
              status.SUBMITTED_TYPE,
              status.SUBMITTED,
              'capitalCommitmentFund2'
            ).capital
          ),
          readyToClose: formatter.format(
            getStatusInfo(
              [...f.investorFunds, ...f.limitedPartnerFunds],
              status.READY_TO_CLOSE,
              'capitalCommitmentFund2'
            ).capital
          ),
          id: `${f._id}/2`
        });
      }
      if (f.fund3LegalName) {
        multiFundsResults.push({
          fundName: f.fund3LegalName,
          allCapital: calculateAllCapital(f, 'capitalCommitmentFund3'),
          capitalClosed: calculateAmountClosedToDateMultiFund3(f),
          notYetReadyToClose: formatter.format(
            getNotYetReadyToCloseStatus(
              [...f.investorFunds, ...f.limitedPartnerFunds],
              status.IN_PROGRESS,
              status.PENDING,
              status.SUBMITTED_TYPE,
              status.SUBMITTED,
              'capitalCommitmentFund3'
            ).capital
          ),
          readyToClose: formatter.format(
            getStatusInfo(
              [...f.investorFunds, ...f.limitedPartnerFunds],
              status.READY_TO_CLOSE,
              'capitalCommitmentFund3'
            ).capital
          ),
          id: `${f._id}/3`
        });
      }
    });
  }
  return multiFundsResults;
};

import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { Header } from './components/molecules/Header';
import { AuthProvider } from './hooks/auth-context';
import { AxiosProvider } from './hooks/axios-context';
import { UserProvider } from './hooks/user-context';
import { Routes } from './navigation';
import './styles/App.css';

export const App: React.FC = () => {
  return (
    <AxiosProvider>
      <AuthProvider>
        <UserProvider>
          <Router>
            <Header />
            <Routes />
          </Router>
        </UserProvider>
      </AuthProvider>
    </AxiosProvider>
  );
};

import axios, { AxiosResponse, AxiosStatic } from 'axios';
import React, {
  Context,
  ContextType,
  createContext,
  // useCallback,
  useContext,
  useEffect,
  useState
  // useState
} from 'react';

// import { useCurrentUser } from './user-context';
// import { Native as Sentry } from 'sentry-expo';

// import { useAuth } from './auth-context';

/**
 * Setup of Axios to include auth headers and error
 * logs across the app
 */
export interface AxiosContextProps {
  axios: AxiosStatic;
  axiosContextReady: boolean;
  get(route: string): Promise<AxiosResponse>;
  patch(route: string, body: any): Promise<AxiosResponse>;
  post(route: string, body: any): Promise<AxiosResponse>;
  put(route: string, body: any): Promise<AxiosResponse>;
}

export const AxiosContext = createContext<AxiosContextProps>({
  axios,
  axiosContextReady: false,
  get: () => new Promise(() => undefined),
  patch: () => new Promise(() => undefined),
  post: () => new Promise(() => undefined),
  put: () => new Promise(() => undefined)
});

export const AxiosProvider: React.FC = ({ children }) => {
  const [axiosContextReady, setAxiosContextReady] = useState<boolean>(false);
  // const { user } = useCurrentUser();

  // Basic init that should only happen once
  useEffect(() => {
    axios.defaults.baseURL = '/api';
    axios.defaults.headers.post['Content-Type'] = 'application/json';

    const authToken = localStorage.getItem('auth-client-token');

    if (typeof window !== 'undefined' && authToken) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
    }

    axios.interceptors.request.use(
      async (config) => {
        const authToken = localStorage.getItem('auth-client-token');
        config.headers.Authorization = `Bearer ${authToken}`;
        return config;
      },
      (error) => {
        // Sentry.captureException(error?.response);
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        // Sentry.captureException(error?.response);
        return Promise.reject(error);
      }
    );

    setAxiosContextReady(true);
  }, []); // eslint-disable-line

  // Check when there's no user and remove auth
  //
  // useEffect(() => {
  //   if (!user) {
  //     axios.defaults.headers.common['Authorization'] = '';
  //     localStorage.clear();
  //   }
  // }, []);

  const get = async (route: string): Promise<AxiosResponse> => {
    const result = await axios.get(route);

    return result?.data?.result;
  };

  const patch = async (route: string, body: any): Promise<AxiosResponse> => {
    const result = await axios.patch(route, body);

    return result?.data?.result;
  };

  const post = async (route: string, body: any): Promise<AxiosResponse> => {
    const result = await axios.post(route, body);

    return result?.data?.result;
  };

  const put = async (route: string, body: any): Promise<AxiosResponse> => {
    const result = await axios.put(route, body);

    return result?.data?.result;
  };

  const context = {
    axios,
    axiosContextReady,
    get,
    patch,
    post,
    put
  };

  return (
    <AxiosContext.Provider value={context}>{children}</AxiosContext.Provider>
  );
};

export const refreshAuthToken = (authToken: string): string =>
  (axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`);

export const useAxios = (): ContextType<Context<AxiosContextProps>> => {
  return useContext(AxiosContext);
};

import React from 'react';
import styled from 'styled-components/macro';

import { colors } from './../../constants/colors';
import { Link } from './../atoms/Link';

export type PaginationType = {
  next: () => void;
  page: number;
  pages: number[];
  previus: () => void;
  selected: (e?: React.MouseEvent<HTMLElement>) => void;
};

interface PaginationProps {
  data?: PaginationType;
}

export const Pagination: React.FC<PaginationProps> = ({ data }) => {
  return (
    <>
      {data && data.pages.length > 1 && (
        <Container>
          {data.page && data.page < data.pages.length && (
            <Link displayText=">>" onClick={data.next} />
          )}
          {data.pages.map((_page: number) => (
            <Link
              bolder={data.page === _page + 1}
              displayText={_page + 1}
              onClick={data.selected}
            />
          ))}
          {data.page && data.page > 1 && (
            <Link displayText="<<" onClick={data.previus} />
          )}
        </Container>
      )}
    </>
  );
};

const Container = styled.div`
  margin: 25px 25px 0 0;
  & a {
    color: ${colors.light.backgroundTitle};
    margin-left: 10px;
  }
`;

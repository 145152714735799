export const pagination = {
  MAX: 25
};

export const fundHeaders = [
  { key: 'fundName', label: 'Fund Name' },
  { key: 'allCapital', label: 'All Commitments' },
  { key: 'notYetReadyToClose', label: 'Not Yet Ready to Close' },
  { key: 'readyToClose', label: 'Ready to Close' },
  { key: 'capitalClosed', label: 'Closed Capital' }
];

export const fundDetailHeaders = [
  { key: 'name', label: 'Name' },
  { key: 'lpGroup', label: 'LP group' },
  { key: 'capitalCommitment', label: 'Capital Commitment' },
  { key: 'status', label: 'Status' },
  { key: 'contactName', label: 'Contact Name' },
  { key: 'contactEmail', label: 'Contact Email' },
  { key: 'notes', label: 'Notes' }
];

import React from 'react';

import { colors } from './../../../constants/colors';

export const NoteIconOpacity: React.FC = () => (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 18 16"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.666016 10.5V2.16667C0.666016 1.25 1.41602 0.5 2.33268 0.5H15.666C16.108 0.5 16.532 0.675595 16.8445 0.988155C17.1571 1.30072 17.3327 1.72464 17.3327 2.16667V14.6667C17.3304 14.8298 17.2803 14.9886 17.1886 15.1235C17.0968 15.2584 16.9676 15.3634 16.8167 15.4255C16.6659 15.4876 16.5001 15.5041 16.34 15.4729C16.1799 15.4417 16.0325 15.3642 15.916 15.25L12.816 12.1667H2.33268C1.89065 12.1667 1.46673 11.9911 1.15417 11.6785C0.84161 11.3659 0.666016 10.942 0.666016 10.5Z"
      fill={colors.light.green}
    />
  </svg>
);

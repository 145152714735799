import React, { FC } from 'react';

interface CountDownTimerProps {
  hours?: number;
  minutes?: number;
  seconds?: number;
  setTimeOut: (v: boolean) => void;
  text?: string;
}

interface ICountdown {
  hours: number;
  minutes: number;
  seconds: number;
}

const CountDownTimer: FC<CountDownTimerProps> = ({
  hours = 0,
  minutes = 0,
  seconds = 0,
  setTimeOut,
  text
}) => {
  const [time, setTime] = React.useState<ICountdown>({
    hours,
    minutes,
    seconds
  });

  const tick = (): void => {
    if (time.hours === 0 && time.minutes === 0 && time.seconds === 1) {
      setTimeOut(true);
    } else if (time.hours === 0 && time.seconds === 0) {
      setTime({ hours: time.hours - 1, minutes: 59, seconds: 59 });
    } else if (time.seconds === 0) {
      setTime({ hours: time.hours, minutes: time.minutes - 1, seconds: 59 });
    } else {
      setTime({
        hours: time.hours,
        minutes: time.minutes,
        seconds: time.seconds - 1
      });
    }
  };

  React.useEffect(() => {
    const timerId = setInterval(() => tick(), 1000);
    return () => clearInterval(timerId);
  });

  return (
    <div>
      <p>{text}</p>
    </div>
  );
};

export default CountDownTimer;

import React from 'react';
import { CSVLink } from 'react-csv';
import styled from 'styled-components/macro';

import { Button } from './../../components/atoms/Button';
import { DownloadIcon } from './../../components/atoms/icons/DownloadIcon';
import {
  EInputAlign,
  EInputSize,
  TextInput
} from './../../components/atoms/TextInput';
import { colors } from './../../constants/colors';
import { fundDetailType, fundType } from './../../constants/tableColumns';

interface HeaderProps {
  csvTitle?: string;
  data?: Array<fundType | fundDetailType>;
  headers?: any[];
  onSearch?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  search?: string;
  title: string;
}

export const TableHeader: React.FC<HeaderProps> = ({
  csvTitle,
  data,
  headers,
  onSearch,
  search,
  title
}) => {
  const placeholder = title.split('All ')[1];

  return (
    <Wrapper>
      <HeaderBrand>
        <Title>{title}</Title>
      </HeaderBrand>
      <TableOptions>
        <StyledTextInput
          align={EInputAlign.Center}
          name="search-investors"
          onChange={onSearch}
          placeholder={`Search ${placeholder ? placeholder : title}`}
          search
          value={search}
          width={EInputSize.Small}
        />
      </TableOptions>
      <TableOptions>
        <CSVLink
          data={data || []}
          filename={`${csvTitle} ${title}.csv`}
          headers={headers}
        >
          <Button displayText="Export" onClick={() => {}} path="">
            <DownloadIcon />
          </Button>
        </CSVLink>
      </TableOptions>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

const HeaderBrand = styled.div`
  flex: 8;
`;

const TableOptions = styled.div`
  flex: 2;
  font-size: 20px;
  font-weight: 500;
  margin-left: 5px;
`;

const StyledTextInput = styled(TextInput)`
  // font-size: 24px;
  // margin-right: 100px;
  // position: absolute;
  // top: 100px;
`;

const Title = styled.h1`
  color: ${colors.light.black};
  font-size: 24px;
  font-weight: 400;
  line-height: 23px;
  text-align: left;
`;

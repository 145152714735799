import React from 'react';
import styled from 'styled-components/macro';

import { colors } from './../../constants/colors';

interface LinkProps {
  bolder?: boolean;
  displayText?: string | number;
  onClick?: (e?: React.MouseEvent<HTMLElement>) => void;
}

export const Link: React.FC<LinkProps> = ({ bolder, displayText, onClick }) => {
  return (
    <StyledLink bolder={bolder} onClick={onClick} type="button">
      {displayText}
    </StyledLink>
  );
};

const StyledLink = styled.a<LinkProps>`
  align-items: center;
  color: ${colors.dark.green};
  cursor: pointer;
  display: flex;
  float: right;
  font-size: 14px;
  font-weight: ${(props) => (props.bolder ? '900' : '400')};
  line-height: 16px;
  text-align: right;
`;

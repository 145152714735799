import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { useCurrentUser } from '../hooks/user-context';

interface IProtectedRoute {
  component: React.ComponentType<any>;
  exact: boolean;
  key: string;
  path: string;
}

export const ProtectedRoute: React.FC<IProtectedRoute> = ({
  component: Component,
  exact,
  key,
  path
}) => {
  const { user } = useCurrentUser();

  return (
    <Route
      exact={exact}
      key={key}
      path={path}
      render={(props) => {
        if (user) {
          return <Component {...props} />;
        }

        return <Redirect to="/login" />;
      }}
    />
  );
};

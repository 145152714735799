import React, {
  Context,
  ContextType,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
// import { Native as Sentry } from 'sentry-expo';

import { User } from '../types/User';
import { useAuth } from './auth-context';
import { useAxios } from './axios-context';

export interface UserContextProps {
  isLoading: boolean;
  // setUser(user: User): void;
  // user: User | null;
  setUser(user: User): void;
  user: User | null;
}

export const UserContext = createContext<UserContextProps>({
  isLoading: false,
  setUser: () => undefined,
  user: null
});

export const UserProvider: React.FC = ({ children }) => {
  const { user: userFromLogin } = useAuth();
  const { axios, axiosContextReady } = useAxios();
  const [userData, setUserData] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getUser = useCallback(async () => {
    try {
      const response = await axios.get('/clients/userinfo');

      if (response?.data?.user) {
        setUserData(response.data.user);
      }

      setIsLoading(false);
    } catch (error) {
      console.log('error ---', error);
      setIsLoading(false);
    }
  }, [axios]);

  useEffect(() => {
    const authToken = localStorage.getItem('auth-client-token');

    if (!authToken) {
      setIsLoading(false);
    }

    if (userFromLogin) {
      setUserData(userFromLogin);
      setIsLoading(false);
    }

    if (axiosContextReady && authToken) {
      getUser();
    }
  }, [axiosContextReady, getUser, userFromLogin]);

  const setUser = (user: User): void => setUserData(user);

  const context = {
    isLoading,
    setUser,
    user: userData
  };

  return (
    <UserContext.Provider value={context}>{children}</UserContext.Provider>
  );
};

export const useCurrentUser = (): ContextType<Context<UserContextProps>> =>
  useContext(UserContext);

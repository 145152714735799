import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import 'react-responsive-modal/styles.css';
import { useHistory } from 'react-router';
import styled from 'styled-components/macro';

import 'react-phone-input-2/lib/style.css';
import { Button, EButtonAlign } from '../components/atoms/Button';
import { Link } from '../components/atoms/Link';
import { EInputSize, TextInput } from '../components/atoms/TextInput';
import { colors } from '../constants/colors';
import { useAuth } from '../hooks/auth-context';
import { LoginFormData } from './../types/formTypes';
import { validateEmail } from './../utils/validations';
import AuthenticationModal from './authetication-modal';

export const Login: React.FC = () => {
  const { login, logout } = useAuth();
  const [twoFA, setTwoFA] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const {
    // formState: { errors },
    handleSubmit,
    register
  } = useForm<LoginFormData>();
  const { replace } = useHistory();

  const [open, setOpen] = useState(true);

  const onCloseModal = (): void => setOpen(false);

  const handleOnClick = async ({
    email,
    password
  }: LoginFormData): Promise<void> => {
    if (validateEmail(email)) {
      setLoading(true);
      const response = await login(email, password);
      if (response?.success) {
        setTwoFA(true);
        setErrorMsg('');
      } else {
        setErrorMsg('Please enter a correct username and password.');
      }
      setLoading(false);
    } else {
      setErrorMsg('Your email does not follow the format rules.');
    }
  };

  return (
    <Wrapper>
      <Container>
        <form onSubmit={handleSubmit(handleOnClick)}>
          <DescriptionMessage>Client Access</DescriptionMessage>
          <div>
            <TextInput
              label="Email Address"
              name="email"
              register={() =>
                register('email', {
                  required: true,
                  setValueAs: (value: string) => value.trim()
                })
              }
              width={EInputSize.Full}
            />
          </div>
          <div>
            <TextInput
              label="Password"
              name="password"
              register={() => register('password')}
              type="password"
              width={EInputSize.Full}
            />
          </div>
          <StyledSection>
            <Link
              displayText="Forgot Password?"
              onClick={() => replace('/forgot-password')}
            />
          </StyledSection>
          <FooterSection>
            {errorMsg ? <ErrorMessage>{errorMsg}</ErrorMessage> : null}
            <Button
              disabled={loading ? true : false}
              displayText={loading ? 'Loading...' : 'Sign In'}
              iconAlign={EButtonAlign.Right}
              type="submit"
            />
          </FooterSection>
        </form>
      </Container>
      {twoFA && (
        <AuthenticationModal
          logout={logout}
          onCloseModal={onCloseModal}
          open={open}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  height: auto;
  justify-content: center;
  margin: 40px auto;
  margin-top: 150px;
  max-width: 450px;
  text-align: center;
`;

const DescriptionMessage = styled.p`
  align-items: center;
  color: ${colors.dark.green};
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
`;

const Container = styled.div`
  width: 520px;
`;

const StyledSection = styled.div`
  height: 15px;
  margin-top: 15px;
`;

// const GenericIcon = styled.div`
//   border: 1px solid ${colors.dark.green};
//   border-radius: 50%;
//   color: ${colors.dark.green};
//   float: left;
//   margin: 20px 30px;
//   text-align: center;
//   width: 20px;
// `;

const ErrorMessage = styled.div`
  color: ${colors.errors.primary};
  margin-bottom: 15px;
  margin-top: 15px;
  text-align: center;
`;

const FooterSection = styled.div`
  margin-top: 20px;
`;

import { useCurrentUser } from 'hooks/user-context';
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components/macro';

import { LeftArrowIcon } from '../components/atoms/icons/LeftArrowIcon';
import { Menu } from '../components/molecules/Menu';
import { colors } from '../constants/colors';

interface LayoutProps {
  title?: string | StringConstructor;
}

export const Layout: React.FC<LayoutProps> = ({ children, title }) => {
  const [redirect, setRedirect] = useState('');
  const { user } = useCurrentUser();

  if (!user) {
    return <Redirect to="/login" />;
  }

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <LayoutContainer id="layout">
      <Header />

      <Main>
        <Sidebar>
          <Title>{user?.firm.name}</Title>
          <Menu />
        </Sidebar>

        <PageContainerWrapper>
          <PageContainer>
            <Content>
              {title ? (
                <>
                  <BackToIcon onClick={() => setRedirect('/funds')}>
                    <LeftArrowIcon />
                  </BackToIcon>
                  <PageTitle>{title}</PageTitle>
                </>
              ) : null}
              {children}
            </Content>
          </PageContainer>
        </PageContainerWrapper>
      </Main>
    </LayoutContainer>
  );
};

const BackToIcon = styled.div`
  cursor: pointer;
`;

const PageTitle = styled.h3`
  font-weight: normal;
  margin: -20px 0 50px 0;
  text-align: center;
`;

const Content = styled.div`
  background-color: ${colors.light.white};
  border-radius: 8px;
  padding: 28px;
`;

const Header = styled.header`
  height: 64px;
`;

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Main = styled.main`
  display: flex;
  height: calc(100% - 110px);
  width: 100%;
`;

const PageContainer = styled.div`
  background-color: ${colors.light.green};
  border-radius: 8px;
  padding: 23px;

  @media screen and (max-width: 1100px) {
    width: 1000px;
  }
`;

const PageContainerWrapper = styled.div`
  background-color: ${colors.light.white};
  overflow: auto;
  padding-right: 80px;
  width: 100%;
  @media screen and (max-width: 1100px) {
    padding-right: 0;
    width: 1000px;
  }
`;

const Sidebar = styled.aside`
  min-width: 204px;
  width: 204px;

  @media screen and (min-width: 1100px) {
    min-width: 304px;
    width: 304px;
  }
`;

const Title = styled.h1`
  color: ${colors.light.black};
  font-size: 22px;
  font-weight: 500;
  line-height: 23px;
  margin-bottom: 40px;
  margin-left: 80px;

  @media screen and (max-width: 1100px) {
    margin-left: 20px;
  }
`;

import React from 'react';
import styled from 'styled-components/macro';

import { colors } from './../../constants/colors';
import { TooltipAmount } from './Tooltips';

interface InfoBoxProps {
  amount: number | string;
  fullAmount?: string | number;
  title: string;
}

export const InfoBox: React.FC<InfoBoxProps> = ({
  amount,
  fullAmount,
  title
}) => {
  return (
    <Wrapper>
      <HeaderOptions>
        <span>{title}</span>
        <StyledSection>
          <Container>
            <TooltipAmount>
              <span className="highlight">
                {amount}
                <ContainerFlex>
                  <p>Capital</p>
                </ContainerFlex>
              </span>
              {fullAmount && <div className="tooltip-text">{fullAmount}</div>}
            </TooltipAmount>
          </Container>
        </StyledSection>
      </HeaderOptions>
    </Wrapper>
  );
};

const ContainerFlex = styled.div`
  display: flex;
  margin-top: 25px;
  & p,
  span {
    align-items: center;
    color: ${colors.dark.gray};
    flex: 1;
    font-size: 16px;
    font-weight: 100;
    line-height: 16px;
    margin-top: -15px;
    text-align: center;
  }
`;

const Container = styled.div`
  display: grid;
  justify-content: center;
  justify-items: center;
  margin-top: 25px;
  text-align: center;
  & p,
  span {
    align-items: center;
    color: ${colors.dark.gray};
    flex: 1;
    font-size: 18px;
    font-weight: 100;
    line-height: 16px;
    margin-top: -11px;
    text-align: center;
  }
  & .highlight {
    align-items: center;
    color: ${colors.dark.gray};
    font-family: Franklin Gothic, serif;
    font-size: 21px;
    font-weight: normal;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
  }
`;

const HeaderOptions = styled.div`
  background: ${colors.light.green};
  border-radius: 10px;
  padding: 10px;
  padding-bottom: 30px;

  & span {
    color: ${colors.dark.green};
    font-size: 18px;
    font-weight: 500;
    line-height: 19px;
    overflow: hidden;
    position: relative;
    text-align: center;
    text-overflow: ellipsis;
    top: 20px;
    white-space: nowrap;
  }
`;

const StyledSection = styled.div`
  margin-top: 40px;
`;

const Wrapper = styled.div`
  flex: 1;
  margin: 0 8px;
`;

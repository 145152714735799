import React, {
  Context,
  ContextType,
  createContext,
  useCallback,
  useContext,
  useState
} from 'react';
import { SignUpFormData } from 'types/formTypes';
import { User } from 'types/User';
// import { Native as Sentry } from 'sentry-expo';

import { useAxios } from './axios-context';

/**
 * An object containing a set of Authentication
 * methods and properties to be consumed by
 * React components
 */
export interface AuthContextProps {
  isLoading: boolean;
  isLoggedIn: boolean;
  login(
    email: string,
    encryptedPassword: string
  ): Promise<{ success: boolean }>;
  logout(): Promise<void>;
  setIsLoading(isLoading: boolean): void;
  signup(
    form: SignUpFormData,
    token: string,
    email: string
  ): Promise<{ message?: string; success: boolean; user?: User }>;
  user: User | null;
}

export const AuthContext = createContext<AuthContextProps>({
  isLoading: true,
  isLoggedIn: false,
  login: () => new Promise(() => undefined),
  logout: () => new Promise(() => undefined),
  setIsLoading: () => null,
  signup: () => new Promise(() => undefined),
  user: null
});

export const AuthProvider: React.FC = ({ children }) => {
  const { axios } = useAxios();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [user, setUser] = useState<User | null>(null);

  const login = useCallback(
    async (email, encryptedPassword) => {
      if (!email || !encryptedPassword) {
        return { success: false };
      }

      try {
        const response = await axios.post('/clients/login', {
          email,
          encryptedPassword
        });

        const authToken = response?.data?.['access-token'];
        if (authToken) {
          localStorage.setItem('auth-client-token', authToken);
          setUser(response.data.user);

          return { message: response.data.message, success: true };
        }

        return { success: false };
      } catch (error) {
        console.log('error ---', error);
        return { success: false };
      }
    },
    [axios]
  );

  const logout = useCallback(async () => {
    // Talk to DB?
    localStorage.removeItem('auth-client-token');
    window.location.pathname = '/login';
  }, []);

  const signup = useCallback(
    async (form, token, email) => {
      if (!form || !token) {
        return { success: false };
      }
      try {
        const filteredPayload = Object.fromEntries(
          Object.entries(form).filter(([k, v]) => v !== '' && k)
        );
        const payload = {
          email,
          ...filteredPayload
        };
        const { data } = await axios.post(
          `/clients/setup-account`,
          payload,
          getConfigToken(token)
        );

        if (data.success) {
          localStorage.setItem('auth-client-token', token);
          return { message: data.message, success: true, user: data.user };
        } else {
          return { message: data.message, success: false };
        }
      } catch (error) {
        console.log('error ---', error);
        return { success: false };
      }
    },
    [axios]
  );

  const context = {
    isLoading,
    isLoggedIn: false,
    login,
    logout,
    setIsLoading,
    signup,
    user
  };

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};

interface IConfigGetters {
  headers: { Authorization: string };
}

export const getConfig = (): IConfigGetters => {
  return {
    headers: {
      Authorization: `Bearer ${
        localStorage.getItem('auth-client-token')
          ? localStorage.getItem('auth-client-token')
          : 'invalid'
      }`
    }
  };
};

export const getConfigToken = (token: string): IConfigGetters => {
  return {
    headers: {
      Authorization: `Bearer ${token ? token : 'invalid'}`
    }
  };
};

export const useAuth = (): ContextType<Context<AuthContextProps>> => {
  return useContext(AuthContext);
};

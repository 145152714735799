import { ClientType } from 'types/User';

export const validateEmail = (email: string): boolean => {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
  return re.test(email);
};

export const checkPasswordStrength = (
  pass: string,
  confirmPassword: string
): string | null => {
  if (pass.length < 14) {
    return 'Password must be at least 14 characters.';
  }
  if (pass !== confirmPassword) {
    return 'Passwords do not match.';
  }
  return null;
};

export const validateRepeatedEmail = (
  team: ClientType[],
  _email: string
): boolean => {
  let valid = true;
  team.map((item) => {
    if (item.email === _email) {
      valid = false;
    }
  });
  return valid;
};

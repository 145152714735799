import React from 'react';

export const SearchIcon: React.FC = () => (
  <svg
    fill="none"
    height="14"
    viewBox="0 0 14 14"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.87996 8.93335L10.6133 9.66668C10.88 9.65335 11.1666 9.75335 11.3733 9.96002L13.3733 11.96C13.5499 12.1496 13.6461 12.4003 13.6415 12.6594C13.637 12.9184 13.532 13.1656 13.3488 13.3488C13.1656 13.5321 12.9184 13.637 12.6593 13.6416C12.4003 13.6462 12.1495 13.55 11.96 13.3733L9.95996 11.3733C9.86047 11.2743 9.78294 11.1555 9.7324 11.0246C9.68186 10.8936 9.65945 10.7535 9.66663 10.6133L8.9333 9.88002C7.86177 10.7124 6.51327 11.1051 5.16238 10.9781C3.81149 10.8511 2.55978 10.2141 1.66212 9.1966C0.764464 8.17914 0.288351 6.85779 0.330729 5.50161C0.373108 4.14542 0.930791 2.85638 1.89022 1.89695C2.84966 0.937512 4.1387 0.379829 5.49488 0.337451C6.85107 0.295073 8.17242 0.771185 9.18988 1.66884C10.2073 2.5665 10.8444 3.81821 10.9714 5.1691C11.0984 6.51999 10.7057 7.86849 9.87329 8.94002L9.87996 8.93335ZM5.66663 9.66668C6.72749 9.66668 7.74491 9.24526 8.49506 8.49511C9.2452 7.74496 9.66663 6.72755 9.66663 5.66668C9.66663 4.60582 9.2452 3.5884 8.49506 2.83826C7.74491 2.08811 6.72749 1.66668 5.66663 1.66668C4.60576 1.66668 3.58835 2.08811 2.8382 2.83826C2.08806 3.5884 1.66663 4.60582 1.66663 5.66668C1.66663 6.72755 2.08806 7.74496 2.8382 8.49511C3.58835 9.24526 4.60576 9.66668 5.66663 9.66668Z"
      fill="#475352"
    />
  </svg>
);

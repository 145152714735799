import React, { useState } from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Redirect } from 'react-router';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import Swal from 'sweetalert2';

import { AccountFormData, InviteFormData } from '../types/formTypes';
import { Button } from './../components/atoms/Button';
import { Link } from './../components/atoms/Link';
import { EInputSize, TextInput } from './../components/atoms/TextInput';
import { DataTable } from './../components/organisms/DataTable';
import { colors } from './../constants/colors';
import { teamTableColumns } from './../constants/tableColumns';
import { useAuth } from './../hooks/auth-context';
import { useAxios } from './../hooks/axios-context';
import { useCurrentUser } from './../hooks/user-context';
import { Layout } from './../navigation/Layout';
import { ClientType, User } from './../types/User';
import {
  checkPasswordStrength,
  validateEmail,
  validateRepeatedEmail
} from './../utils/validations';

export const Account: React.FC = () => {
  const { axios } = useAxios();
  const [menuIndex, setMenuIndex] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [team, setTeam] = useState<ClientType[]>();
  const { setUser, user } = useCurrentUser();
  const { logout } = useAuth();
  const { replace } = useHistory();

  const accessUserAction = (client: ClientType): void => {
    Swal.fire({
      confirmButtonText: client.accessDisabled ? 'Enable' : 'Disable',
      showCancelButton: true,
      title: `Are you sure you want to ${
        client.accessDisabled ? 'enable' : 'disable'
      } this user?`
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { data } = await axios.post(
          `/clients/${client.accessDisabled ? 'enable' : 'disable'}-firm-user`,
          {
            user: client._id
          }
        );
        if (data.success) {
          const _user: User | any = user;
          _user.firm = data.firm;
          setUser(_user);
          Swal.fire(data.message, '', 'success');
        }
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info');
      }
      fetchTeam();
    });
  };

  const resendInvite = async (client: ClientType): Promise<void> => {
    try {
      const payload = {
        firm: user?.firm._id,
        invitedBy: user,
        resend: true,
        ...client
      };
      await axios.post(`/clients/internal/invite`, payload);
      Swal.fire('Invitation Resent', '', 'success');
      setErrorMsg(null);
    } catch (err) {
      setErrorMsg(err.message);
    }
  };

  const fetchTeam = async (): Promise<void> => {
    const { data } = await axios.get('/clients/userinfo');
    const results = data.user?.firm?.clients.map((client: ClientType) => {
      if (!client.email) return null;
      const content: ClientType = {
        email:
          !client.accessFirstTime && !client.accessDisabled ? (
            client.email
          ) : !client.accessDisabled ? (
            client.email
          ) : (
            <DisabledText>{client.email}</DisabledText>
          ),

        name:
          client.accessFirstTime && !client.accessDisabled ? (
            `${client.firstName} ${client.lastName}`
          ) : (
            <DisabledText>
              {client.accessDisabled ? 'Disabled' : 'Invited'}
            </DisabledText>
          )
      };
      if (client.email !== user?.email) {
        content.action = client.accessFirstTime ? (
          client.accessDisabled ? (
            <Link
              displayText="Enable"
              onClick={() => accessUserAction(client)}
            />
          ) : (
            <Link
              displayText="Disable"
              onClick={() => accessUserAction(client)}
            />
          )
        ) : (
          <Link displayText="Resend" onClick={() => resendInvite(client)} />
        );
      }
      return content;
    });
    setTeam(results);
  };

  useEffect(() => {
    fetchTeam();
  }, []); // eslint-disable-line

  const { handleSubmit, register } = useForm<AccountFormData>({
    defaultValues: {
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? ''
    }
  });

  const {
    handleSubmit: inviteHandleSubmit,
    register: inviteRegister,
    setValue
  } = useForm<InviteFormData>();

  if (!user) {
    return <Redirect to="/login" />;
  }

  const handleOnClick = async (form: AccountFormData): Promise<void> => {
    setLoading(true);
    const payload = {
      email: user.email,
      ...form
    };
    const filteredPayload = Object.fromEntries(
      Object.entries(payload).filter(([k, v]) => v !== '' && k)
    );
    try {
      let passwordPassed = null;
      let refresh = false;
      if (filteredPayload.newPassword && payload.confirmNewPassword) {
        refresh = true;
        passwordPassed = checkPasswordStrength(
          payload.newPassword,
          payload.confirmNewPassword
        );
      }
      if (!passwordPassed) {
        const { data } = await axios.post(
          `/clients/accountDetails`,
          filteredPayload
        );
        if (data.success) {
          await Swal.fire(data.message, '', 'success');
          setErrorMsg(null);
          if (refresh) {
            logout();
            replace('/login');
          }
        } else {
          setErrorMsg(data.message || 'Something went wrong');
        }
      } else {
        setErrorMsg(passwordPassed);
      }
    } catch (err) {
      setErrorMsg(err.message);
    }

    setLoading(false);
  };

  const handleInvite = async (form: InviteFormData): Promise<void> => {
    setLoading(true);
    const payload = {
      firm: user.firm._id,
      invitedBy: user,
      ...form
    };
    if (validateEmail(payload.email)) {
      try {
        if (validateRepeatedEmail(team ?? [], payload.email)) {
          const { data } = await axios.post(
            `/clients/internal/invite`,
            payload
          );
          if (data.success) {
            fetchTeam();
            Swal.fire('Invitation sent to:', payload.email, 'success');
            setValue('email', '');
            setValue('firstName', '');
            setValue('lastName', '');
            setErrorMsg(null);
          } else {
            setErrorMsg(
              'This email address is already associated with another account.'
            );
          }
        } else {
          setErrorMsg(
            'This email address is already associated with this account.'
          );
        }
      } catch (err) {
        setErrorMsg(err.message);
      }
    } else {
      setErrorMsg('You have entered an invalid email address!');
    }
    setLoading(false);
  };

  const options = [
    {
      content: (
        <Wrapper>
          <form onSubmit={handleSubmit(handleOnClick)}>
            <Container>
              <GroupFields>
                <StyledField>
                  <TextInput
                    label="First Name"
                    name="firstName"
                    register={() => register('firstName')}
                    width={EInputSize.Small}
                  />
                </StyledField>
                <StyledField>
                  <TextInput
                    label="Last Name"
                    name="lastName"
                    register={() => register('lastName')}
                    width={EInputSize.Small}
                  />
                </StyledField>
              </GroupFields>
            </Container>

            <Container>
              <GroupFields>
                <StyledField>
                  <TextInput
                    disabled
                    label="Email Address"
                    name="emailAddress"
                    value={user.email}
                    width={EInputSize.Large}
                  />
                  <EmailText>
                    To change the email address for your account, please contact{' '}
                    <a href="mailto:fundportaladmin@gunder.com">
                      fundportaladmin@gunder.com
                    </a>
                    .
                  </EmailText>
                </StyledField>
              </GroupFields>
            </Container>

            <Container>
              <GroupFields>
                <StyledField>
                  <TextInput
                    label="Current Password"
                    name="currentPassword"
                    register={() => register('currentPassword')}
                    type="password"
                    width={EInputSize.Small}
                  />
                </StyledField>
              </GroupFields>
            </Container>

            <Container>
              <GroupFields>
                <StyledField>
                  <TextInput
                    label="New Password"
                    name="newPassword"
                    register={() => register('newPassword')}
                    type="password"
                    width={EInputSize.Small}
                  />
                </StyledField>
                <StyledField>
                  <TextInput
                    label="Confirm New Password"
                    name="confirmNewPassword"
                    register={() => register('confirmNewPassword')}
                    type="password"
                    width={EInputSize.Small}
                  />
                </StyledField>
              </GroupFields>
            </Container>
            {errorMsg ? <ErrorMessage>{errorMsg}</ErrorMessage> : null}
            <FooterSection>
              <Button
                displayText={loading ? 'Loading...' : 'Save'}
                type="submit"
              />
            </FooterSection>
          </form>
        </Wrapper>
      ),
      description:
        'View your account details; change your password or account name.',
      title: 'Account Details'
    },
    {
      content: (
        <Wrapper>
          <SectionTitle>Team</SectionTitle>
          <StyledWrapper>
            <DataTable
              cancelSort={() => {}}
              columns={teamTableColumns}
              data={team || []}
              hideHeader
              onRowClick={() => {}}
              sortHandler={() => {}}
            />
          </StyledWrapper>
          <SectionTitle>Invite</SectionTitle>
          <form onSubmit={inviteHandleSubmit(handleInvite)}>
            <FormContainer>
              <TextInput
                label=""
                name="firstName"
                placeholder="First Name"
                register={() => inviteRegister('firstName')}
                width={EInputSize.XSmall}
              />
              &nbsp;
              <TextInput
                label=""
                name="lastName"
                placeholder="Last Name"
                register={() => inviteRegister('lastName')}
                width={EInputSize.XSmall}
              />
              &nbsp;
              <TextInput
                label=""
                name="email"
                placeholder="Email Address"
                register={() => inviteRegister('email')}
                width={EInputSize.XSmall}
              />
            </FormContainer>
            {errorMsg ? <ErrorMessage>{errorMsg}</ErrorMessage> : null}
            <FooterSection>
              <Button
                displayText={loading ? 'Loading...' : 'Invite'}
                type="submit"
              />
            </FooterSection>
          </form>
        </Wrapper>
      ),
      description: `Manage the team members in your organization who have access to the Fund Formation Portal. 
        Anyone added to your team will be able to add or disable team members, and view all your firm’s funds in the portal.`,
      title: 'Team Management'
    }
  ];
  return (
    <Layout>
      <Title>My Account</Title>
      <Container>
        <SubMenuColumn>
          <UL>
            {options.map((item, idx) => (
              <LI
                key={`${idx}-key`}
                onClick={() => {
                  setErrorMsg('');
                  setMenuIndex(idx);
                }}
              >
                <div
                  className={menuIndex === idx ? 'is-active' : 'custom-padding'}
                >
                  <h5>{item.title}</h5>
                  <p>{item.description}</p>
                </div>
              </LI>
            ))}
          </UL>
        </SubMenuColumn>
        <ContentColumn>{options[menuIndex].content}</ContentColumn>
      </Container>
    </Layout>
  );
};

const EmailText = styled.p`
  font-size: 14px;
  text-align: left;

  a {
    color: ${colors.dark.green};
  }
`;

const FormContainer = styled.form`
  display: flex;
`;
const DisabledText = styled.span`
  color: ${colors.dark.backgroundTitle};
`;

const StyledWrapper = styled.div`
  margin-bottom: 50px;
`;

const ErrorMessage = styled.div`
  color: ${colors.errors.primary};
  margin-bottom: 15px;
  margin-top: 15px;
`;

const FooterSection = styled.div`
  float: right;
  margin-top: 25px;
  width: 100px;
`;

const GroupFields = styled.div`
  display: flex;
`;

const StyledField = styled.div`
  margin-right: 20px;
`;

const Wrapper = styled.div`
  justify-content: center;
  margin: -20px auto;
  max-width: 520px;
  text-align: center;
`;

const Container = styled.div`
  display: flex;
`;

const SubMenuColumn = styled.div`
  flex: 1;
`;

const ContentColumn = styled.div`
  flex: 2;
  padding: 25px;
`;

const UL = styled.ul`
  margin: 0;
  padding: 0;
`;

const SectionTitle = styled.li`
  border-radius: 10px;
  color: ${colors.light.backgroundTitle};
  cursor: pointer;
  list-style-type: none;
  margin-bottom: 25px;
  margin-left: -15px;
  padding: 2px 15px;
  text-align: left;
`;

const LI = styled.li`
  color: ${colors.light.backgroundTitle};
  cursor: pointer;
  list-style-type: none;
  margin-bottom: 25px;

  & p {
    font-size: 14px;
    line-height: 24px;
  }

  & h5 {
    font-weight: 500;
    font-size: 16px;
  }

  .custom-padding {
    padding: 2px 15px;
  }

  .is-active {
    background: ${colors.light.green};
    border-radius: 15px;
    color: ${colors.dark.green};
    padding: 2px 15px;
  }
`;

const Title = styled.h1`
  color: ${colors.light.black};
  font-size: 22px;
  font-weight: 500;
  line-height: 23px;
  margin-bottom: 40px;
  text-align: left;
`;

import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';

import { colors } from '../../constants/colors';
import { fonts } from '../../constants/fonts';
import { useAuth } from '../../hooks/auth-context';
import { routes } from '../../navigation';
import { Button } from '../atoms/Button';
import { LogoutIcon } from '../atoms/icons/LogoutIcon';

export const Menu: React.FC = () => {
  const { logout } = useAuth();

  const handleLogout = (): void => {
    logout();
  };

  return (
    <MenuContainer>
      <UL>
        {routes.map(
          (route, idx) =>
            route.isMenuItem && (
              <LI key={`${route}_${idx}`}>
                {route.path === window.location.pathname ? (
                  <IconWrap>{route.icon && <route.icon />}</IconWrap>
                ) : (
                  route.icon && <route.icon />
                )}
                <StyledNavLink
                  activeClassName="is-active"
                  exact={!!route.exact}
                  to={route.path}
                >
                  {route.title}
                </StyledNavLink>
              </LI>
            )
        )}
      </UL>
      <FooterMenu>
        <Button displayText="Sign Out" onClick={handleLogout} path="/">
          <LogoutIcon />
        </Button>
      </FooterMenu>
    </MenuContainer>
  );
};

const LI = styled.li`
  list-style-type: none;
  margin-bottom: 25px;

  .is-active {
    color: ${colors.dark.green};
  }
`;

const MenuContainer = styled.section`
  padding: 0 40px 0;

  @media screen and (max-width: 1100px) {
    padding: 0 15px 0;
  }
`;

const FooterMenu = styled.div`
  margin-top: 300px;
  position: relative;
`;

const StyledNavLink = styled(NavLink)`
  color: ${colors.light.black};
  font-family: ${fonts.primary};
  font-size: 18px;
  margin-left: 18px;
  position: relative;
  text-decoration: none;
  top: -3.5px;
`;

const UL = styled.ul`
  margin: 0;
  padding: 0;
`;

const IconWrap = styled.span`
  & svg path {
    fill: ${colors.dark.green};
  }
`;

import React from 'react';

import { colors } from 'constants/colors';

interface Props {
  color?: string;
}

export const UserIcon: React.FC<Props> = ({ color }) => (
  <svg
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0007 10C8.89558 10 7.83577 9.56103 7.05437 8.77963C6.27297 7.99823 5.83398 6.93842 5.83398 5.83335C5.83398 4.72828 6.27297 3.66848 7.05437 2.88708C7.83577 2.10567 8.89558 1.66669 10.0007 1.66669C11.1057 1.66669 12.1655 2.10567 12.9469 2.88708C13.7283 3.66848 14.1673 4.72828 14.1673 5.83335C14.1673 6.93842 13.7283 7.99823 12.9469 8.77963C12.1655 9.56103 11.1057 10 10.0007 10Z"
      fill={color || colors.dark.gray}
    />
    <path
      d="M17.5 16.6667V15.8334C17.5 14.7283 17.061 13.6685 16.2796 12.8871C15.4982 12.1057 14.4384 11.6667 13.3333 11.6667H6.66667C5.5616 11.6667 4.50179 12.1057 3.72039 12.8871C2.93899 13.6685 2.5 14.7283 2.5 15.8334V16.6667C2.5 17.5834 3.25 18.3334 4.16667 18.3334H15.8333C16.2754 18.3334 16.6993 18.1578 17.0118 17.8452C17.3244 17.5326 17.5 17.1087 17.5 16.6667Z"
      fill={color || colors.dark.gray}
    />
  </svg>
);

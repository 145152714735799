import React, { CSSProperties } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';

import { colors } from './../../constants/colors';

export enum EButtonAlign {
  Left,
  Right
}
interface ButtonProps {
  disabled?: boolean;
  displayText: string;
  iconAlign?: EButtonAlign;
  onClick?: () => void;
  path?: string;
  style?: CSSProperties;
  type?: 'button' | 'submit';
}

export const Button: React.FC<ButtonProps> = ({
  children,
  disabled,
  displayText,
  iconAlign,
  onClick,
  path,
  style,
  type = 'button'
}) => {
  return (
    <StyledButton
      disabled={disabled}
      onClick={onClick}
      style={style}
      type={type}
    >
      {iconAlign === EButtonAlign.Right ? (
        <StyledWrapper>{children}</StyledWrapper>
      ) : (
        children
      )}
      {path ? (
        <StyledLink to={path}>{displayText}</StyledLink>
      ) : (
        <StyledText>{displayText}</StyledText>
      )}
    </StyledButton>
  );
};

const StyledWrapper = styled.div`
  float: right;
  margin-right: 10px;
`;

const StyledLink = styled(NavLink)`
  color: ${colors.light.white};
  text-decoration: none;
  :focus,
  :hover,
  :visited,
  :link,
  :active {
    text-decoration: none;
  }
`;

const StyledText = styled.span`
  color: ${colors.light.white};
`;

const StyledButton = styled.button`
  background: ${(props) =>
    props.disabled ? colors.light.green : colors.dark.green};
  border-color: transparent;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  left: 448px;
  line-height: 16px;
  pointer-events: ${(props) => (props.disabled ? 'none' : null)};
  top: 618px;
  & svg {
    position: relative;
    right: 8px;
    top: 3px;
  }
  width: 100%;
`;
